import { JSX, createMemo } from 'solid-js'
import { usePageContext } from 'vike-solid/usePageContext'

export type LinkProps = {
  href: string
} & JSX.HTMLAttributes<HTMLAnchorElement>

export function Link(props: LinkProps) {
  const pageContext = usePageContext()
  const isActive = createMemo(() =>
    props.href === '/'
      ? pageContext.urlPathname === props.href
      : pageContext.urlPathname.startsWith(props.href),
  )
  return (
    <a
      {...props}
      href={props.href}
      classList={{
        'is-active': isActive(),
        ...props.classList,
      }}
    />
  )
}
